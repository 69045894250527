import { Box, FormControlLabel, makeStyles, RadioGroup, Typography } from "@material-ui/core";
import { useContext, useState } from "react";
import { post } from "../../config/api";
import BookingFormLabel from "../BookingFormLabel";
import BookingFormTextInput from "../BookingFormTextInput";
import BookingFormWrapper from "../BookingFormWrapper";
import StyledButton from "../StyledButton";
import StyledRadio from "../StyledRadio";
import {React} from 'react';
import { SnackBarContext } from "../../context/SnackBarContext";
import ReCAPTCHA from "react-google-recaptcha";
import ReCaptcha from "../ReCaptcha";
import { GOOGLE_RECAPTCHA_API_KEY } from "../../config/constant";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    diveider: {
        marginTop: 50,
        marginBottom: 20
    },
    button: {
        '& > p > h3': {
            margin: 0
        },
        marginTop: 10,
        height: 50,
        width: 180,
        [theme.breakpoints.down("sm")]:{
            width: '100%'
        }
    },
    otpReminder: {
        fontSize: 14,
        color: "rgba(0, 0, 0, 0.38)"
    },
    buttonDisabled: {
        cursor: 'not-allowed'
    }
}));

const VerifyForm = ({
    formik,
    validateOTPToken = (id, token) => (e) => {}
}) => {

    const classes = useStyles();
    const intl = useIntl();

    const [otpIdentifier, setOtpIdentifier] = useState(null);
    const [sendingOtpRequest, setSendingOtpRequest] = useState(false);
    const [token, setToken] = useState('');
    const {setErrorMessage} = useContext(SnackBarContext);

    const [countDown, setCountDown] = useState(0);

    const handleGetToken = async (e) => {

        e.preventDefault();
        try{
            setSendingOtpRequest(true);
            const res = await getOTPToken(intl.locale);

            if (res){
                setCountDown(300);
    
                let countDownForOtp = setInterval(() => {
                    setCountDown(countDown => {
                        if (countDown > 0){
                            return countDown - 1;
                        }
                        clearInterval(countDownForOtp);
                        clearOtpIdentifier();
                        return 0;
                    });
                }, 1000);
            }
        } catch (e) {

        } finally {
            setSendingOtpRequest(false);
        }
    }

    const label = countDown ? `${intl.formatMessage({id: "otp.get.the"})} ${countDown}s` : `${intl.formatMessage({id: "otp.get.the"})}`;

    const getOTPToken = async (locale) => {

        if (formik.values['primary_contact_type_id'] == 1){ // choose mobile
            if (!formik.values['mobile']){
                formik.setFieldError('mobile', intl.formatMessage({id: "form.otp.mobile.required"}));
                return false;
            }
        }else{ // choose email
            if (!formik.values['email']){
                formik.setFieldError('email', intl.formatMessage({id: "form.otp.email.required"}));
                return false;
            } 
        }

        const data = {
            'email': formik.values['email'],
            'mobile': formik.values['mobile'],
            'primary_contact_type_id': formik.values['primary_contact_type_id'],
            'edit': 1,
            'lang': locale == 'zh' ? 'zh' : (locale == 'zh-hant' ? 'zhant' : 'en') 
        };

        try{
            const res = await post('/otp/request', data);
            setOtpIdentifier(res.idetifier);
            return true;
        } catch (err){
            const statusCode = err.response.data.status_code;
            if (statusCode == 0){
                setErrorMessage(err.response.data.msg);
            }
            return false;
        }
    }

    const handleValidateClick = (e) => {
        e.preventDefault();
        validateOTPToken(otpIdentifier, token);
    }

    const handleTokenChange = (e) => {
        setToken(e.target.value);
    }

    const clearOtpIdentifier = () => {
        setOtpIdentifier(null);
    }

    return (
        <form>
            <BookingFormWrapper>
            <BookingFormLabel label={intl.formatMessage({id: "form.primary.contact"})} required/>
                <RadioGroup row name="primary_contact_type_id" value={formik.values['primary_contact_type_id']} onChange={formik.handleChange}>
                <FormControlLabel value="1" control={<StyledRadio />} label={intl.formatMessage({id: "form.mobile"})} disabled={formik.values['otpVerified'] || otpIdentifier}/>
                    <FormControlLabel value="2" control={<StyledRadio />} label={intl.formatMessage({id: "form.email"})} disabled={formik.values['otpVerified'] || otpIdentifier}/>
                </RadioGroup>
                {
                    formik.values['primary_contact_type_id'] == 1 ? 
                    <BookingFormTextInput
                        name="mobile"
                        placeholder="12345678"
                        value = {formik.values['mobile']}
                        onChange={formik.handleChange}
                        error = {formik.errors['mobile'] ? true : false}
                        helperText = {formik.errors['mobile']}
                        title={intl.formatMessage({id: "form.mobile"})}
                    /> : 
                    <BookingFormTextInput
                        name="email"
                        placeholder="example@gmail.com"
                        value = {formik.values['email']}
                        onChange={formik.handleChange}
                        error = {formik.errors['email'] ? true : false}
                        helperText = {formik.errors['email']}
                        title={intl.formatMessage({id: "form.email"})}
                    />
                }
                {
                    formik.values['otpVerified'] ?
                    ""
                    : 
                    <StyledButton
                        filled
                        className={classes.button}
                        label={label}
                        onClick={handleGetToken}
                        type = "button"
                        disabled = {otpIdentifier || sendingOtpRequest}
                    />
                }
                <BookingFormWrapper>
                    <BookingFormLabel 
                        label={intl.formatMessage({id: "form.enter.otp"})}
                    />
                    <Typography className={classes.otpReminder}>
                        <FormattedMessage id="otp.reminder"/>
                    </Typography>
                    <BookingFormTextInput
                        value = {token}
                        onChange = {handleTokenChange}
                        disabled={formik.values['otpVerified']}
                        title={intl.formatMessage({id: "form.enter.otp"})}
                    />
                    {                
                        formik.values['otpVerified'] ? <StyledButton
                            filled
                            className={classes.button}
                            label={intl.formatMessage({id: "form.verified"})}
                            type = "button"
                            disabled
                        /> 
                        : 
                        <StyledButton
                            className={classes.button}
                            label={intl.formatMessage({id: "form.verify"})}
                            onClick={handleValidateClick}
                            type = "button"
                            disabled = {!otpIdentifier}
                        />
                    }
                </BookingFormWrapper>

                <BookingFormWrapper>
                    <ReCaptcha
                        sitekey={GOOGLE_RECAPTCHA_API_KEY}
                        formik={formik}
                    />
                    {
                        formik.errors['captcha'] && formik.touched['captcha'] && 
                        <Typography>
                            <Box component="p" fontSize={18} fontWeight="500" margin={0} color='#B30900'>
                                {formik.errors['captcha']}
                            </Box>
                        </Typography>
                    }
                </BookingFormWrapper>
            </BookingFormWrapper>
        </form>
    );
}

export default VerifyForm;