import { Box, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import { withStyles } from "@material-ui/styles";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import BackToHomeButton from "../../../component/BackToHomeButton";
import useFetch from "../../../hook/useFetch";
import {React} from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import fieldLangConvertor from "../../../helper/fieldLangConvertor";
import { localeToLangCode } from "../../../helper/localeConvert";
import usePaginationFetch from "../../../hook/usePaginationFetch";

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 20,
        [theme.breakpoints.down("xs")]:{
            fontSize: 18
        }
    },
    body: {
        fontSize: 20,
        [theme.breakpoints.down("xs")]:{
            fontSize: 18
        }
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      cursor: 'pointer'
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    paginationRoot: {
        marginTop: '20px'
    }
}));

const LatestNewsPage = () => {

    const {
        entities,
        isLoading,
        page,
        pageSize,
        total,
        handlePageChange,
    } = usePaginationFetch({
        api: '/announcement',
        initFilter: {
            status: 1,
        }
    });

    const history = useHistory();
    const intl = useIntl();
    const classes = useStyles();

    const handleRowClick = id => e => {
        history.push(`/${localeToLangCode(intl.locale)}/latestNews/${id}`);
    }

    const onPageChange = (e, page) => {
        handlePageChange({page: page - 1});
    } 

    return (
        <Fragment>
            <Typography variant="h1">
                <FormattedMessage id="newspage.title"/>
            </Typography>

            <TableContainer>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{
                                width: '16%',
                                minWidth: 95
                            }}><FormattedMessage id="newspage.date"/></StyledTableCell>
                            <StyledTableCell><FormattedMessage id="newspage.news"/></StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            (entities || []).map(row => (
                                <StyledTableRow key={row.id} onClick={handleRowClick(row.id)}>
                                    <StyledTableCell style={{
                                        width: '16%',
                                        minWidth: 95
                                    }}>{row.display_date}</StyledTableCell>
                                    <StyledTableCell>{fieldLangConvertor(row, 'title', intl.locale)}</StyledTableCell>
                                </StyledTableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                <Grid className={classes.paginationRoot} container justifyContent="center">
                    <Grid item>
                        <Pagination count={Math.ceil(total/pageSize)} page={page} onChange={onPageChange} color="primary"/>
                    </Grid>
                </Grid>
            </TableContainer>
    
            <BackToHomeButton
                filled={false}
            />
        </Fragment>
    );
}

export default LatestNewsPage;