import { Box, Grid, Typography } from "@material-ui/core";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Fragment, useContext, useState, React} from "react";
import useAccordion from "../../hook/useAccordion";
import { AccordionContext } from "../../context/AccordionContext";
import BookingAccordion from "../BookingAccordion";
import { formatTime } from "../../helper/dateTimeFormat";
import SessionPicker from "../SessionPicker";
import LocationPicker from "../LocationPicker";
import { put } from "../../config/api";
import { SnackBarContext } from "../../context/SnackBarContext";
import { useHistory } from "react-router-dom";
import PageTitle from "../PageTitle";
import { useIntl } from "react-intl";
import fieldLangConvertor from "../../helper/fieldLangConvertor";
import { localeToLangCode } from "../../helper/localeConvert";

const GeneralEditBooking = ({
    record
}) => {

    const {setSuccessMessage, setErrorMessage} = useContext(SnackBarContext);

    const history = useHistory();
    const intl = useIntl();

    const formik = useFormik({
        initialValues: {
            session_id: "",
            verify_moblie: record.mobile,
            verify_email: record.email,
        },
        validationSchema: Yup.object({
           
        }),
        onSubmit: values => {
            put(`/bookingRecord/${record.id}`, values)
            .then(res => {
                history.push(`/${localeToLangCode(intl.locale)}/editDetails`, res);
            })
            .catch(err => {
                const statusCode = err.response.data.status_code;
                const msgCode = err.response.data.msg_code;
                if (statusCode == 0){
                    if (msgCode){
                        setErrorMessage(intl.formatMessage({id: `error.msg.${msgCode}`}));
                    }else{
                        setErrorMessage(err.response.data.msg);
                    }         
                }
            });
        }
    });

    const purposeId = record.session.purpose_id;

    const title = purposeId == 1 ? intl.formatMessage({id: "giftpage.title"}) : intl.formatMessage({id: "complaintpage.title"});
    
    const progressSubtitle = [
        intl.formatMessage({id: "bookingpage.subtitle1"}),
        intl.formatMessage({id: "bookingpage.subtitle2"}),
    ];

    const [branch, setBranch] = useState(record.session.branch);
    const {progress, setProgress, handleForward, handleBackward, toProgress} = useAccordion({
        totalNumOfAccordion: 2,
        onSubmit: formik.handleSubmit
    });
    const [selectedSession, setSelectedSession] = useState(record.session);

    const handleSessionSelected = (session) => {
        setSelectedSession(session);
        formik.setFieldValue("session_id", session.id);
    }

    const handleBranchChange = (branch) => {
        setBranch(branch);
        setSelectedSession(null);
    }

    return (
        <Fragment>

            <PageTitle>
                {title}
            </PageTitle>

            <Typography variant="h2">
                {progressSubtitle[progress]}
            </Typography>

            <AccordionContext.Provider value={{progress, setProgress, handleForward, handleBackward, toProgress}}>
                <Grid container justify="center" spacing={1}>
                    <Grid item xs={11}>
                        <BookingAccordion 
                            title={`1. ${intl.formatMessage({id: "bookingpage.accordion.title1"})}`}
                            value={branch ? fieldLangConvertor(branch, 'branch_name', intl.locale) : null}
                            backText={intl.formatMessage({id: "bookingpage.accordion.back1"})}
                            nextText={intl.formatMessage({id: "bookingpage.accordion.next1"})}
                            order={0}
                        >
                            <LocationPicker value={branch} onChange={handleBranchChange} purposeId={purposeId}/>
                        </BookingAccordion>
                    </Grid>

                    <Grid item xs={11}>
                        <BookingAccordion 
                            title={`2. ${intl.formatMessage({id: "bookingpage.accordion.title2"})}`}
                            value={selectedSession ? `${selectedSession.date} ${formatTime(selectedSession.start_time)}` : null}
                            order={1}
                            backText={intl.formatMessage({id: "bookingpage.accordion.back2"})}
                            nextText={intl.formatMessage({id: "bookingpage.accordion.next2"})}
                            forwardAble={selectedSession ? true : false}
                        >
                            <SessionPicker
                                branchId={branch ? branch.id : null}
                                purposeId={purposeId}
                                value={selectedSession}
                                onChange={handleSessionSelected}
                            />
                        </BookingAccordion>
                    </Grid>
                </Grid>
            </AccordionContext.Provider>
        </Fragment>
    );
}

export default GeneralEditBooking;