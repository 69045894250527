import { Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { Link } from "react-router-dom";
import StyledButton from "../StyledButton";
import {React} from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { localeToLangCode } from "../../helper/localeConvert";

const useStyles = makeStyles((theme) => ({
    button: {
        width: 250,
        marginTop: 50
    }
}));

const BackToHomeButton = ({
    justify = 'center',
    link = '/',
    className,
    label = <FormattedMessage id="newspage.back"/>,
    filled = true
}) => {

    const classes = useStyles();

    const intl = useIntl();

    return (
        <Grid container justify={justify}>
            <Grid item>
                <Link to={`/${localeToLangCode(intl.locale)}${link}`}>
                    <StyledButton
                        filled = {filled}
                        label={label}
                        className={clsx(classes.button, className)}
                    />
                </Link>
            </Grid>
        </Grid>
    );
}

export default BackToHomeButton;