import { createTheme, responsiveFontSizes } from "@material-ui/core";

let theme = createTheme({
    palette: {
        primary: {
            light: '#e3f7f5',
            main: '#2ccab5',
            dark: "#429b85"
        }
    },
    typography: {
        h1: {
            fontSize: '2rem',
            fontWeight: 'bold',
            marginTop: 20,
            marginBottom: 20,
            textAlign: 'center',
        },
        h2: {
            fontSize: '1.7rem',
            marginTop: 20,
            marginBottom: 20,
            textAlign: 'center'
        },
        h3: {
            fontSize: '1.3rem',
            fontWeight: 'bold',
        },
        p: {
            fontSize: '1rem'
        }
    }
});

theme = responsiveFontSizes(theme);

export default theme;