import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useContext } from "react";
import { SessionPickerContext } from "../../../context/SessionPickerContext";
import {React} from 'react';
import { FormattedMessage } from "react-intl";
import WcagIconButton from "../../WcagIconButton";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0 8px'
    },
    margin: {
        margin: theme.spacing(1),
    },
    daysHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: '16px',
        marginBottom: 10
    },
    dayLabel: {
        margin: '0 2px',
        textAlign: 'center',
        flex: 'calc(100%/7)'
    },
    buttonHidden: {
        opacity: 0,
        pointerEvents: 'none'
    },
    week: {
        display: 'flex',
        justifyContent: 'center'
    },
    day: {
        color: 'rgba(0, 0, 0, 0.87)',
        width: '48px',
        height: '48px',
        padding: 0,
        fontSize: '0.75rem',
        fontWeight: 500
    },
    transitionContainer: {
        marginTop: '12px'
    },
    unavaliable: {
        pointerEvents: 'none',
        color: 'rgba(0, 0, 0, 0.38)',
    },
    selectedDay: {
        backgroundColor: theme.palette.primary.main,
        color: '#000000',
        pointerEvents: 'none'
    },
    iconRoot: {
        '&:hover':{
            backgroundColor: theme.palette.primary.main
        }
    },
    reminder: {
        fontSize: '1rem'
    }
}));

const DayHeadline = ({
    children
}) => {

    const classes = useStyles();

    return (
        <Typography className={classes.dayLabel}>
            <Box component="span">
                {children}
            </Box>
        </Typography>
    );
}

const CalenderDays = ({
    date,
    onChange
}) => {
    const classes = useStyles();

    const totalDays = parseInt(new Date(date.getFullYear(), date.getMonth()+1, 0).getDate());
    const firstDay = (parseInt(new Date(date.getFullYear(), date.getMonth(), 0).getDay()) + 1)%7;
    const totalWeek = Math.ceil((totalDays + firstDay)/7);

    let headingSpace = firstDay;
    let day = 1;

    let weeks = [];

    const handleDayClick = clickedDate => e => {
        if (clickedDate == null) return;
        onChange(clickedDate);
    }

    for(let week = 0; week<totalWeek; week++){
        let gridInWeek = [];
        for (let i = 0; i<7; i++){

            const today = new Date();
            today.setHours(0,0,0,0);

            const current_date = new Date(date.getFullYear(), date.getMonth(), day);

            const showText = headingSpace? false : day > totalDays ? false : true;

            const unavaliable = (current_date < today) || (current_date.getDay() == 0 || current_date.getDay() == 6);
            date.setHours(0,0,0,0);
            const selectedDay = current_date.getTime() == date.getTime();

            gridInWeek.push(
                <div role="presentation" className={classes.dayLabel} key={week*7 + i}>
                    <WcagIconButton 
                        value={showText ? day : -1} 
                        className={clsx(
                            classes.day, 
                            {
                                [classes.buttonHidden]: !showText, 
                                [classes.unavaliable]: unavaliable, 
                                [classes.selectedDay]: selectedDay
                            })
                        }
                        classes={{
                            root: classes.iconRoot
                        }}
                        onClick={handleDayClick(showText ? current_date : null)}
                        label={day}
                    >
                        <Typography>
                            <Box component="p" style={{fontWeight: 'bold'}}>
                                {showText ? day : ''}
                            </Box>
                        </Typography>
                    </WcagIconButton>
                </div>
            );
    
            headingSpace ? headingSpace -= 1 : day += 1;
        }

        weeks.push(<div className={classes.week} key={week}>{gridInWeek}</div>);
    }

    return (<div>{weeks}</div>);
}

const Calender = () => {
    
    const classes = useStyles();
    const {date, setDate, nextMonth, lastMonth, disableLastMonth} = useContext(SessionPickerContext);
   
    return (
        <div className={classes.root}>    
            <div className={classes.daysHeader}>
                <DayHeadline>Su</DayHeadline>
                <DayHeadline>Mo</DayHeadline>
                <DayHeadline>Tu</DayHeadline>
                <DayHeadline>We</DayHeadline>
                <DayHeadline>Th</DayHeadline>
                <DayHeadline>Fr</DayHeadline>
                <DayHeadline>Sa</DayHeadline>
            </div>

            <CalenderDays date={date} onChange={setDate}/>

            <Typography>
                <Box component="h1" className={classes.reminder}>
                    <FormattedMessage id="sessionpicker.reminder"/>
                </Box>
            </Typography>
        </div>
    );
}

export default Calender;