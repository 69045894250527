import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, Typography } from "@material-ui/core";
import { useContext, React } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { _delete } from "../../config/api";
import { SnackBarContext } from "../../context/SnackBarContext";
import { formatTime } from "../../helper/dateTimeFormat";
import fieldLangConvertor from "../../helper/fieldLangConvertor";
import { localeToLangCode } from "../../helper/localeConvert";
import InfoText from "../InfoText";
import StyledButton from "../StyledButton";

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main
    },
    text: {
        margin: '10px 0',
    },
    button: {
        '& h3': {
            margin: 0,
            fontWeight: 400
        },
        width: 'auto',
        height: 'auto',
        border: '1px solid #2ccab5',
        padding: '10px 15px'
    }
}));

const DeleteConfirmationDialog = ({
    open,
    onClose,
    record
}) => {

    const classes = useStyles();
    const intl = useIntl();

    const {setErrorMessage} = useContext(SnackBarContext);

    const history = useHistory();

    if (!record) return "";

    const onSubmit = id => e => {
        _delete(`/bookingRecord/${id}`)
        .then(res => {
            history.push(`/${localeToLangCode(intl.locale)}/cancelConfirm`);
        })
        .catch(err => {
            const statusCode = err.response.data.status_code;
            if (statusCode == 0){
                setErrorMessage(err.response.data.msg);
            }
        });
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="cancel-dialog-title"
            aria-describedby="cancel-dialog-description"
            maxWidth='lg'
        >
            <DialogTitle id="cancel-dialog-title">
                <Typography>
                    <Box component="h2" className={classes.title}>
                        <FormattedMessage id="booking.cancel.dialog.title"/>
                    </Box>
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={5} sm={4} md={3}>
                        <InfoText component="h3" className={classes.text}><FormattedMessage id="managepage.listing.datetime"/>:</InfoText> 
                    </Grid>

                    <Grid item xs={7} sm={8} md={9}>
                        <InfoText component="h3" className={classes.text}>{`${record.session.date} ${formatTime(record.session.start_time)}`}</InfoText> 
                    </Grid>

                    <Grid item xs={5} sm={4} md={3}>
                        <InfoText component="h3" className={classes.text}><FormattedMessage id="managepage.listing.service"/>:</InfoText> 
                    </Grid>

                    <Grid item xs={7} sm={8} md={9}>
                        <InfoText component="h3" className={classes.text}>{record.session.purpose_id == 1 ? <FormattedMessage id="giftpage.title"/> : <FormattedMessage id="complaintpage.title"/>}</InfoText> 
                    </Grid>

                    <Grid item xs={5} sm={4} md={3}>
                        <InfoText component="h3" className={classes.text}><FormattedMessage id="managepage.listing.location"/>:</InfoText> 
                    </Grid>

                    <Grid item xs={7} sm={8} md={9}>
                        <InfoText component="h3" className={classes.text}>{fieldLangConvertor(record.session.branch, 'branch_address', intl.locale)}</InfoText> 
                    </Grid>

                    <Grid item xs={5} sm={4} md={3}>
                        <InfoText component="h3" className={classes.text}><FormattedMessage id="managepage.listing.ref"/>:</InfoText> 
                    </Grid>

                    <Grid item xs={7} sm={8} md={9}>
                        <InfoText component="h3" className={classes.text}>{record.ref_code}</InfoText> 
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <StyledButton
                    label={intl.formatMessage({id: "booking.cancel.dialog.close"})}
                    onClick={onClose}
                    className={classes.button}
                />

                <StyledButton
                    label={intl.formatMessage({id: "booking.cancel.dialog.cancel"})}
                    filled
                    className={classes.button}
                    onClick={onSubmit(record.id)}
                />
            </DialogActions>
        </Dialog>
    );
}

export default DeleteConfirmationDialog;