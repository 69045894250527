import { Typography } from "@material-ui/core";
import clsx from "clsx";

const PageTitle = ({
    children,
    className
}) => (
    <Typography variant="h1" className={clsx(className)}>
        {children}
    </Typography>
);

export default PageTitle;