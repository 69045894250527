import { AppBar, Container, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { FormattedMessage, useIntl} from "react-intl";
import {React} from 'react';
import externalLink from "../../config/externalLink";
import { localeToLangCode } from "../../helper/localeConvert";

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: '#4a4a4a',
        minHeight: 140,
        marginTop: 30,
        fontFamily: theme.typography.fontFamily
    },
    divider:{
        width: 1,
        height: 10,
        display: 'inline-block',
        backgroundColor: '#ffffff',
        margin: '0 5px'
    },
    footerLink: {
        color: "#ffffff",
        fontSize: 14,
        textDecoration: 'none',
        '&:hover': {
            opacity: '0.85'
        }
    },
    footerText: {
        color: '#ffffff',
        fontSize: 14
    },
    copyRightWrapper: {
        [theme.breakpoints.down("xs")]:{
            justifyContent: 'flex-start'
        }
    }
}));

const Footer = () => {

    const classes = useStyles();

    const intl = useIntl();

    return (
        <AppBar position="static" className={classes.footer}>
            <Container>
                <Grid container justify="space-between">
                    <Grid item xs={12} sm={6}>
                        <Typography variant="a"><a className={classes.footerLink} href={`/${localeToLangCode(intl.locale)}/sitemap`}><FormattedMessage id="footer.sitemap"/></a></Typography>
                        <Divider className={classes.divider} orientation="vertical"/>
                        <Typography variant="a"><a className={classes.footerLink} href={externalLink['copyright'][intl.locale]} target="_blank"><FormattedMessage id="footer.copyright"/></a></Typography>
                        <Divider className={classes.divider} orientation="vertical"/>
                        <Typography variant="a"><a className={classes.footerLink} href={externalLink['codeOnAccess'][intl.locale]} target="_blank"><FormattedMessage id="footer.codeOnAccess"/></a></Typography>
                        <Divider className={classes.divider} orientation="vertical"/>
                        <Typography variant="a"><a className={classes.footerLink} href={externalLink['privacy'][intl.locale]} target="_blank"><FormattedMessage id="footer.privacy"/></a></Typography>
                        <Divider className={classes.divider} orientation="vertical"/>
                        <Typography variant="a"><a className={classes.footerLink} href={externalLink['accessibilityStatement'][intl.locale]} target="_blank"><FormattedMessage id="footer.accessibilityStatement"/></a></Typography>
                    </Grid>
                    <Grid container item xs={12} sm={4} justify="flex-end" className={classes.copyRightWrapper}>
                    <Typography variant="p"><span className={classes.footerText}><FormattedMessage id="footer.reservedStatement"/></span></Typography>
                    </Grid>
                </Grid>
            </Container>
        </AppBar>
    );
}

export default Footer;