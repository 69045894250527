import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core"
import { useEffect } from "react";
import {React} from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { SessionPickerContext } from "../../../context/SessionPickerContext";
import { formatDate } from "../../../helper/dateTimeFormat";
import useDate from "../../../hook/useDate";
import useFetch from "../../../hook/useFetch";
import Calender from "./Calender";
import SessionList from "./SessionList";
import SwitchHeader from "./SwitchHeader";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
        border: '1px solid rgb(0 0 0 / 40%)'
    },
    text: {
        fontSize: '1.7rem',
        fontWeight: 'bold'
    }
}));

const DesktopPicker = ({
    purposeId, 
    branchId,
    value,
    onChange
}) => {

    const classes = useStyles();

    const {date, setDate, nextMonth, lastMonth, disableLastMonth} = useDate({
        checkDisableDate: (date) => {
            return (date.getDay() == 0 || date.getDay() == 6)
        }
    });

    const {entities: sessions, setReady, handleFilterChange} = useFetch({
        api: '/session',
        initFilter: {
            date: formatDate(date),
            purpose: purposeId,
            branch: branchId
        },
        readyToFetch: false
    });

    useEffect(() => {
        if (date && branchId){
            setReady(true);
        }else{
            setReady(false);
        }

        handleFilterChange({
            date: formatDate(date),
            purpose: purposeId,
            branch: branchId
        })
    }, [date, branchId]);
    
    return (
        <SessionPickerContext.Provider value={{date, setDate, nextMonth, lastMonth, disableLastMonth}}>
            <Paper className={classes.root} variant="outlined">
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <SwitchHeader 
                            date={date}
                            nextMonth={nextMonth}
                            lastMonth={lastMonth}
                            disableLastMonth={disableLastMonth}
                        />  
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h2"  className={classes.text}>
                            <FormattedMessage id="sessionpicker.avaliabletime"/>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Calender/>
                    </Grid>

                    <Grid item xs={6}>
                        <SessionList sessions={sessions} onClick={onChange} selected={value}/>
                    </Grid>
                </Grid>
            </Paper>
        </SessionPickerContext.Provider>
    );
}

export default DesktopPicker;