import { Box, Grid, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import { Fragment, useCallback, useContext, useEffect, useRef } from "react";
import { AccordionContext } from "../../../context/AccordionContext";
import useAccordion from "../../../hook/useAccordion";
import * as Yup from 'yup';
import { get, post } from "../../../config/api";
import VerifyForm from "../../../component/VerifyForm";
import { useHistory, useRouteMatch } from "react-router-dom";
import BookingAccordion from "../../../component/BookingAccordion";
import {React} from 'react';
import RedStar from "../../../component/RedStar";
import PageTitle from "../../../component/PageTitle";
import { FormattedMessage, useIntl } from "react-intl";
import { SnackBarContext } from "../../../context/SnackBarContext";

const EntryPage = () => {

    const {setErrorMessage} = useContext(SnackBarContext);

    const { path, url } = useRouteMatch();
    const history = useHistory();
    const intl = useIntl();

    const recaptchaRef = useRef();

    const formik = useFormik({
        initialValues: {
            primary_contact_type_id: "1",
            email: "",
            mobile: "",
            otpVerified: false,
            captcha: false
        },
        validationSchema: Yup.object({
            email: Yup.string().email(intl.formatMessage({id: "form.email.validate"})).nullable(),
            mobile: Yup.string().max(8, intl.formatMessage({id: "form.mobile.length.validate"})).min(8, intl.formatMessage({id: "form.mobile.length.validate"})).nullable(),
            otpVerified: Yup.bool().oneOf([true], intl.formatMessage({id: "form.otpVerified.required"})),
            captcha: Yup.bool().oneOf([true], intl.formatMessage({id: "form.captcha.required"})),
            captcha_sign: Yup.string().required(intl.formatMessage({id: "form.captcha.sign.required"})),
        }),
        onSubmit: async (values) => {
            const {otpVerified, captcha_sign, ...data} = values;

            const params = new URLSearchParams(data);

            const res = await get(`/bookingRecord?${params.toString()}`);

            history.push(`${path}/listing`, {
                listOfRecords: res,
                verify_moblie: data['mobile'],
                verify_email: data['email'],
            });

            // try{
            //     const formValidRes = await post('/validCaptcha', {
            //         captcha_sign: captcha_sign
            //     });

            //     const params = new URLSearchParams(data);

            //     const res = await get(`/bookingRecord?${params.toString()}`);

            //     history.push(`${path}/listing`, res);

            // } catch (err){
            //     const statusCode = err.response.data.status_code;
            //     const msgCode = err.response.data.msg_code;
            //     if (statusCode == 0){
            //         if (msgCode){
            //             setErrorMessage(intl.formatMessage({id: `error.msg.${msgCode}`}));
            //         }else{
            //             setErrorMessage(err.response.data.msg);
            //         }         
            //     }
            //     recaptchaRef.current.reset();
            // }
        }
    });

    useEffect(() => {
        formik.validateForm();
    }, [intl]);

    const {progress, setProgress, handleForward, handleBackward, toProgress} = useAccordion({
        totalNumOfAccordion: 1,
        onSubmit: formik.handleSubmit
    });

    const validateOTPToken = useCallback(async (id, token) => {

        const data = {
            idetifier: id,
            token: token
        };

        try{
            const res = await post('/otp/validate', data);

            if (res.status){
                formik.setFieldValue("otpVerified", true);
            }else{
                formik.setFieldValue("otpVerified", false);
                setErrorMessage(intl.formatMessage({id: 'otp.validation.error'}));
            }
        } catch (e) {
            setErrorMessage(intl.formatMessage({id: 'otp.validation.error'}));
        }
    }, [formik]);

    return (
        <Fragment>
            <PageTitle>
                <FormattedMessage id="managepage.entry.title"/>
            </PageTitle>

            <Typography variant="h2">
                <FormattedMessage id="managepage.entry.subtitle"/>
            </Typography>

            <AccordionContext.Provider value={{progress, setProgress, handleForward, handleBackward, toProgress}}>
                <Grid container justify="center" spacing={1}>
                    <Grid item xs={12}>
                        <BookingAccordion 
                            title={`1. ${intl.formatMessage({id: "bookingpage.accordion.title3"})}`}
                            order={0}
                            backText={intl.formatMessage({id: 'managepage.entry.back'})}
                            nextText={intl.formatMessage({id: 'managepage.entry.next'})}
                            forwardAble={formik.values['otpVerified'] && formik.values['captcha']}
                            helperText={(
                                <Typography>
                                    <Box color='#B30900'>
                                        <RedStar/> <FormattedMessage id="form.necessary"/>
                                    </Box>
                                </Typography>
                            )}
                        >
                            <VerifyForm
                                formik={formik}
                                validateOTPToken={validateOTPToken}
                            />
                        </BookingAccordion>
                    </Grid>
                </Grid>
            </AccordionContext.Provider>
        </Fragment>
    );
}

export default EntryPage;