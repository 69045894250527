import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { formatTime } from "../../../helper/dateTimeFormat";
import {React} from 'react';

const useStyles = makeStyles((theme) => ({
    session: {
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: '#000000'
        },
        width: '100%',
        borderRadius: 30,
        padding: '10px 0',
        backgroundColor: theme.palette.primary.light,
        color: '#0B6471'
    },
    sessionButtonText: {
        margin: 0
    },
    sessionDisable: {
        backgroundColor: '#f0f0f0',
        borderColor: '#f0f0f0 !important'
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        color: '#000000'
    }
}));

const SessoinButton = ({
    session,
    onClick,
    selected
}) => {

    const classes = useStyles();

    const active = session.active;
    const fulled = session.occupied >= session.booking_capacity;
    const openedToBook = session.openedToBook;
    const closedToBook = session.closedToBook;

    const cannotBookYet = (!openedToBook && !closedToBook);
    const expiriedToBook = (openedToBook && closedToBook);

    const disable = !active || fulled || cannotBookYet || expiriedToBook;

    return (
        <Button 
            key={session.id} 
            onClick={onClick(session)} 
            className={clsx(classes.session, {
                [classes.selected]: selected,
                [classes.sessionDisable]: disable
            })} 
            variant="outlined" 
            size="large" 
            color="primary" 
            disabled={disable}
        >
            <Typography>
                <Box component="h3" className={classes.sessionButtonText}>
                    {`${formatTime(session.start_time)}`}
                </Box>
            </Typography>
        </Button>
    );
}

const SessionList = ({
    sessions,
    onClick,
    selected
}) => {

    const handleClick = session => e => {
        onClick(session);
    }

    const selectedId = selected ? selected.id : null;

    return (
        <Grid container spacing={3}>
            {
                (sessions || []).map(
                    session => 
                    <Grid item xs={4} key={session.id}>
                        <SessoinButton  
                            session={session} 
                            onClick={handleClick} 
                            selected={selectedId == session.id}
                        />
                    </Grid>
                )
            }
        </Grid>
    );
}

export default SessionList;