import { Box, makeStyles, Typography, RadioGroup, FormControlLabel, Divider} from "@material-ui/core";
import StyledRadio from "../StyledRadio";
import StyledCheckBox from "../StyledCheckBox";
import { useState, React } from "react";
import { apiRequest, get, post } from "../../config/api";
import BookingFormLabel from "../BookingFormLabel";
import BookingFormTextInput from "../BookingFormTextInput";
import BookingFormWrapper from "../BookingFormWrapper";
import { Link } from "react-router-dom";
import RedStar from "../RedStar";
import StyledButton from "../StyledButton";
import { FormattedMessage, useIntl } from "react-intl";
import ReCAPTCHA from "react-google-recaptcha";
import ReCaptcha from "../ReCaptcha";
import { GOOGLE_RECAPTCHA_API_KEY } from "../../config/constant";
import { localeToLangCode } from "../../helper/localeConvert";

const useStyles = makeStyles((theme) => ({
    diveider: {
        marginTop: 50,
        marginBottom: 20
    },
    button: {
        '& > p > h3': {
            margin: 0
        },
        marginTop: 10,
        height: 50,
        width: 180,
        [theme.breakpoints.down("sm")]:{
            width: '100%'
        }
    },
    radioGroup: {
        marginBottom: 10
    },
    otpReminder: {
        fontSize: 14,
        color: "rgba(0, 0, 0, 0.38)"
    }
}));

const BookingForm = ({
    formik,
    validateOTPToken = (id, token) => (e) => {},
    purposeId = 2,
    recaptchaRef
}) => {

    const classes = useStyles();
    const intl = useIntl();

    const [otpIdentifier, setOtpIdentifier] = useState(null);
    const [sendingOtpRequest, setSendingOtpRequest] = useState(false);
    const [token, setToken] = useState('');

    const [countDown, setCountDown] = useState(0);

    const handleGetToken = async (e) => {

        e.preventDefault();
        try{
            setSendingOtpRequest(true);
            const res = await getOTPToken(intl.locale);

            if (res){
                setCountDown(300);
    
                let countDownForOtp = setInterval(() => {
                    setCountDown(countDown => {
                        if (countDown > 0){
                            return countDown - 1;
                        }
                        clearInterval(countDownForOtp);
                        clearOtpIdentifier();
                        return 0;
                    });
                }, 1000);
            }
        } catch (e) {

        } finally {
            setSendingOtpRequest(false);
        }
    }

    const label = countDown ? `${intl.formatMessage({id: "otp.get.the"})} ${countDown}s` : `${intl.formatMessage({id: "otp.get.the"})}`;

    const getOTPToken = async (locale) => {

        if (formik.values['primary_contact_type_id'] == 1){ // choose mobile
            if (!formik.values['mobile']){
                formik.setFieldError('mobile', intl.formatMessage({id: "form.otp.mobile.required"}));
                return false;
            }
        }else{ // choose email
            if (!formik.values['email']){
                formik.setFieldError('email', intl.formatMessage({id: "form.otp.email.required"}));
                return false;
            } 
        }

        const data = {
            'email': formik.values['email'],
            'mobile': formik.values['mobile'],
            'primary_contact_type_id': formik.values['primary_contact_type_id'],
            'edit': 0,
            'lang': locale == 'zh' ? 'zh' : (locale == 'zh-hant' ? 'zhant' : 'en') 
        };

        const res = await post('/otp/request', data);
        setOtpIdentifier(res.idetifier);
        return true;
    }

    const handleValidateClick = (e) => {
        e.preventDefault();
        validateOTPToken(otpIdentifier, token);
    }

    const handleTokenChange = (e) => {
        setToken(e.target.value);
    }

    const clearOtpIdentifier = () => {
        setOtpIdentifier(null);
    }

    const handleContactTypeChange = (e) => {
        formik.setFieldValue('primary_contact_type_id', e.target.value);
        formik.setFieldValue('mobile', '');
        formik.setFieldValue('email', '');
    }

    return (
            <form>

                <BookingFormWrapper className={classes.formItem}>
                    <BookingFormLabel label={intl.formatMessage({id: "form.salutation"})}/>
                    <RadioGroup row value={formik.values['salutation']} name="salutation" onChange={formik.handleChange}>
                        <FormControlLabel value="1" control={<StyledRadio />} label={<FormattedMessage id="salutation.label.mr"/>}/>
                        <FormControlLabel value="2" control={<StyledRadio />} label={<FormattedMessage id="salutation.label.mrs"/>}/>
                        <FormControlLabel value="3" control={<StyledRadio />} label={<FormattedMessage id="salutation.label.miss"/>}/>
                    </RadioGroup>
                </BookingFormWrapper>

                <BookingFormWrapper>
                    <BookingFormLabel label={intl.formatMessage({id: "form.name"})} required/>
                    <BookingFormTextInput 
                        name="name" 
                        value={formik.values['name']} 
                        onChange={formik.handleChange}
                        error = {formik.errors['name'] && formik.touched['name']}
                        helperText = {formik.errors['name']}
                        title={intl.formatMessage({id: "form.name"})}
                    />
                </BookingFormWrapper>

                <BookingFormWrapper className={classes.formItem}>
                    <BookingFormLabel label={intl.formatMessage({id: "form.primary.contact"})} required/>
                    <RadioGroup className={classes.radioGroup} row name="primary_contact_type_id" value={formik.values['primary_contact_type_id']} onChange={handleContactTypeChange}>
                        <FormControlLabel value="1" control={<StyledRadio />} label={intl.formatMessage({id: "form.mobile"})} disabled={formik.values['otpVerified'] || otpIdentifier}/>
                        <FormControlLabel value="2" control={<StyledRadio />} label={intl.formatMessage({id: "form.email"})} disabled={formik.values['otpVerified'] || otpIdentifier}/>
                    </RadioGroup>
                    {
                        formik.values['primary_contact_type_id'] == 1 ? 
                        <BookingFormTextInput
                            name="mobile"
                            placeholder="12345678"
                            value = {formik.values['mobile']}
                            onChange={formik.handleChange}
                            error = {formik.errors['mobile'] ? true : false}
                            helperText = {formik.errors['mobile']}
                            disabled={formik.values['otpVerified'] || otpIdentifier}
                            title={intl.formatMessage({id: "form.mobile"})}
                        /> : 
                        <BookingFormTextInput
                            name="email"
                            placeholder="example@gmail.com"
                            value = {formik.values['email']}
                            onChange={formik.handleChange}
                            error = {formik.errors['email'] ? true : false}
                            helperText = {formik.errors['email']}
                            disabled={formik.values['otpVerified'] || otpIdentifier}
                            title={intl.formatMessage({id: "form.email"})}
                        />
                    }
                    {
                        formik.values['otpVerified'] ?
                        ""
                        : 
                        <StyledButton
                            filled
                            className={classes.button}
                            label={label}
                            onClick={handleGetToken}
                            type = "button"
                            disabled = {otpIdentifier || sendingOtpRequest}
                        />
                    }
                </BookingFormWrapper>

                <BookingFormWrapper>
                    <BookingFormLabel 
                        label={intl.formatMessage({id: "form.enter.otp"})}
                        required
                    />
                    <Typography className={classes.otpReminder}>
                        <FormattedMessage id="otp.reminder"/>
                    </Typography>
                    <BookingFormTextInput
                        value = {token}
                        onChange = {handleTokenChange}
                        disabled={formik.values['otpVerified']}
                        error={formik.errors['otpVerified'] && formik.touched['otpVerified']}
                        helperText={formik.errors['otpVerified']}
                        title={intl.formatMessage({id: "form.enter.otp"})}
                    />
                    {
                        
                        formik.values['otpVerified'] ? <StyledButton
                            filled
                            className={classes.button}
                            label={intl.formatMessage({id: "form.verified"})}
                            type = "button"
                            disabled
                        /> 
                        : 
                        <StyledButton
                            className={classes.button}
                            label={intl.formatMessage({id: "form.verify"})}
                            onClick={handleValidateClick}
                            type = "button"
                            disabled = {!otpIdentifier}
                        />
                    }
                </BookingFormWrapper>

                <BookingFormWrapper>
                    <BookingFormLabel label={`${intl.formatMessage({id: "form.second.contact"})} ${intl.formatMessage({id: "form.optional"})}`}/>
                    <RadioGroup className={classes.radioGroup} row value={formik.values['primary_contact_type_id'] == '1' ? '2' : '1'}>
                        <FormControlLabel value="1" control={<StyledRadio />} label={intl.formatMessage({id: "form.mobile"})} disabled/>
                        <FormControlLabel value="2" control={<StyledRadio />} label={intl.formatMessage({id: "form.email"})} disabled/>                       
                    </RadioGroup>
                    <BookingFormTextInput 
                        placeholder={formik.values['primary_contact_type_id'] == '1' ? "example@gmail.com" : "12345678"}
                        name={formik.values['primary_contact_type_id'] == '1' ? "email" : "mobile"}
                        value={formik.values['primary_contact_type_id'] == '1' ? formik.values["email"] : formik.values["mobile"]}
                        onChange={formik.handleChange}
                        error = {formik.values['primary_contact_type_id'] == '1' ? formik.errors["email"] : formik.errors["mobile"]}
                        helperText = {formik.values['primary_contact_type_id'] == '1' ? formik.errors["email"] : formik.errors["mobile"]}
                        title={formik.values['primary_contact_type_id'] == '1' ? intl.formatMessage({id: "form.email"}) : intl.formatMessage({id: "form.mobile"})}
                    />
                </BookingFormWrapper>

                {
                    purposeId == 1 && 
                    <BookingFormWrapper>
                        <BookingFormLabel label={intl.formatMessage({id: "form.sub.code"})}/>
                        <BookingFormTextInput 
                            placeholder={formik.values['subscription_code']}
                            name="subscription_code"
                            value={formik.values['subscription_code']}
                            onChange={formik.handleChange}
                            error = {formik.errors['subscription_code'] == '1'}
                            helperText = {formik.errors['subscription_code'] == '1'}
                            title={intl.formatMessage({id: "form.sub.code"})}
                        />
                    </BookingFormWrapper>
                }

                <BookingFormWrapper className={classes.formItem}>
                    <BookingFormLabel label={intl.formatMessage({id: "form.preferred.lang"})}/>
                    <RadioGroup row value={formik.values['prefer_lang']} name="prefer_lang" onChange={formik.handleChange}>
                        <FormControlLabel value="2" control={<StyledRadio />} label={<FormattedMessage id="preferred.language.label.zhat"/>}/>
                        <FormControlLabel value="1" control={<StyledRadio />} label={<FormattedMessage id="preferred.language.label.eng"/>}/>
                        <FormControlLabel value="3" control={<StyledRadio />} label={<FormattedMessage id="preferred.language.label.zhas"/>}/>
                    </RadioGroup>
                </BookingFormWrapper>

                <Divider className={classes.diveider}/>

                <BookingFormWrapper className={classes.formItem}>
                    <ReCaptcha
                        sitekey={GOOGLE_RECAPTCHA_API_KEY}
                        formik={formik}
                        recaptchaRef={recaptchaRef}
                    />
                    {
                        formik.errors['captcha'] && formik.touched['captcha'] && 
                        <Typography>
                            <Box component="p" fontSize={18} fontWeight="500" margin={0} color='red'>
                                {formik.errors['captcha']}
                            </Box>
                        </Typography>
                    }
                </BookingFormWrapper>

                <BookingFormWrapper>
                    <FormControlLabel 
                        value="agreement" 
                        control={
                            <StyledCheckBox
                                onChange={formik.handleChange}
                                checked={formik.values['agreement']}
                                name="agreement"
                            />
                        } 
                        label={(
                            <Typography component="p" className={classes.text}>
                                {intl.formatMessage({id: "form.agree.to"})}<Link to={`/${localeToLangCode(intl.locale)}/statement`} target="_blank">{intl.formatMessage({id: "form.personal.statement"})}</Link><RedStar/>
                            </Typography>
                        )} 
                    />
                    {
                        formik.errors['agreement'] && formik.touched['agreement'] && 
                        <Typography>
                            <Box component="p" fontSize={18} fontWeight="500" margin={0} style={{
                                color:'#B30900' 
                            }}>
                                {formik.errors['agreement']}
                            </Box>
                        </Typography>
                    }
                </BookingFormWrapper>
            </form>       
    );
}

export default BookingForm;