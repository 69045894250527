import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import ConfirmLogo from "../../component/ConfirmLogo";
import BackToHomeButton from "../../component/BackToHomeButton";
import SystemTitle from "../../component/SystemTitle";
import { FormattedMessage } from "react-intl";
import {React} from 'react';

const useStyles = makeStyles((theme) => ({
    button: {
        width: 250,
    }
}));

const CancelSuccessPage = () => {

    const classes = useStyles();

    return (
        <Container>
            <SystemTitle><FormattedMessage id="system.headline1"/> - <span style={{display: 'inline-block'}}><FormattedMessage id="system.headline2"/></span></SystemTitle>

            <ConfirmLogo/>
    
            <Typography variant="h1">
                <Box component="h1" textAlign="center">
                    <FormattedMessage id="cancelpage.title"/>
                </Box>
            </Typography>
    
            <BackToHomeButton/>
        </Container>
    );
}

export default CancelSuccessPage;